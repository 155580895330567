export const arrToSrcset = (arr) => {
  /* return array of wp image sizes to srcset string
  * arr - array
  */
  let result = '';
  if (arr) {
    ['large', 'medium', 'thumb'].forEach((size) => {
      const widthIndex = `${size}-width`;
      result += `${arr[size]} ${arr[widthIndex]}w, `;
    });
  }
  return result;
};

export const getUrlParameter = (parameter) => {
  const searchString = window.location.search;

  if (searchString) {
    const regex = new RegExp(`(?<=[?&]${parameter}=)[\\w]+(?=&)?`);
    const res = regex.exec(searchString);
    return Array.isArray(res) ? res[0] : undefined;
  }
  return undefined;
};
