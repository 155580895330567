<template>
  <div class="d-flex flex-column justify-content-center">
    <div v-if="!hidepage" class="wet-section">
      <div class="container">
        <div class="wet-section-title text-center">
          <h1 class="empty-ph">
            {{strings.login && strings.login.welcome}}
          </h1>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-8 col-lg-5">
            <form @submit.prevent="submit">
              <div
                class="wet-input wet-input-with-label"
                :class="$v.form.email.$error ? 'wet-input-error' : ''"
              >
                <input
                  id="wet-input-email"
                  type="text"
                  :placeholder="strings.login && strings.login.email"
                  v-model.lazy.trim="$v.form.email.$model"
                >
                <label for="wet-input-email">
                  {{ strings.login && strings.login.email }}
                </label>
              </div>
              <div
                class="wet-input wet-input-with-label"
                :class="$v.form.password.$error ? 'wet-input-error' : ''"
              >
                <input
                  id="wet-input-password"
                  type="password"
                  :placeholder="strings.login && strings.login.password"
                  v-model.trim="$v.form.password.$model"
                >
                <label for="wet-input-password">
                  {{ strings.login && strings.login.password }}
                </label>
              </div>
              <div
                v-if="$v.form.email.$error"
                class="small text-primary mt-n2 mb-3 mx-3"
              >
                {{ this.strings.login && this.strings.login.error_login }}
              </div>
              <div
                v-if="$v.form.password.$error"
                class="small text-primary mt-n2 mb-3 mx-3"
              >
                {{ this.strings.login && this.strings.login.error_login }}
              </div>
              <div class="d-flex">
                <button
                  id="back-button"
                  type="button"
                  class="wet-button wet-button-flat empty-ph"
                  style="min-width:4rem;"
                  @click.prevent="back"
                >{{strings.commonApp && strings.commonApp.back_text}}</button>
                <button
                  id="login-button"
                  type="submit"
                  class="wet-button empty-ph ml-3 flex-fill"
                  :disabled="disabled"
                >{{strings.login && strings.login.button_login}}</button>
              </div>
            </form>
            <div class="text-center text-secondary my-4">
              <router-link
                :to="{ name: 'forgot', params: { ...$route.params } }"
                id="login-remember-link"
              >{{ strings.login && strings.login.forget }}</router-link>
            </div>
            <!-- <div class="wet-separated-title">
              <span>{{ strings.login && strings.login.or }}</span>
            </div>
            <div class="row mt-4">
              <div class="col">
                <button class="wet-button wet-button-outlined btn-block">
                  <i v-html="icons.fb"></i>
                  Facebook
                </button>
              </div>
              <div class="col">
                <button class="wet-button wet-button-outlined btn-block">
                  <i v-html="icons.google"></i>
                  Google
                </button>
              </div>
            </div> -->
            <div
              v-if="['insurance', 'settings'].includes($route.query && $route.query.redirect)"
              class="alert alert-primary my-3"
            >
              {{ strings.login && strings.login.error_login_first }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import wpSection from '@/enums/wpSection';
import LogService from '@/services/LogService';
import configEnv from '@/enums/configEnv';

export default {
  mixins: [common],
  data() {
    return {
      loginApi: '/api/user/login',
      userStatusApi: '/api/user/status/',
      magicOrderApi: '/api/user/magic/order',
      magicLoginApi: '/api/user/magic/login',
      availableUrlRedirect: ['done', 'additional', 'buy', 'password-reset'],
      form: {
        email: '',
        password: '',
      },
      icons: {
        fb: `
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
        </svg>`,
        google: `
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />
        </svg>`,
      },
      prevRoute: null,
      pwdErr: false,
      hidepage: true,
    };
  },
  computed: {
    ...mapState(['strings', 'lang', 'loading']),
    disabled() {
      return this.$v.$anyError || Object.values(this.form).some((el) => !el)
        || this.loading.length > 0;
    },
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          pwdErr: () => !this.pwdErr,
        },
      },
    };
  },
  async created() {
    await this.getWordPressStrings([wpSection.LOGIN], wpSection.LOGIN);
    const res = await this.checkMagicLink();
    if (res) {
      this.$router.push(res);
    } else {
      /** check user status */
      try {
        const res2 = await this.getUserStatus();
        if (['0', '1'].includes(`${res2.data.user?.status}`)) {
          this.$router.push({ name: 'settings', params: { ...this.$route.params } });
          return;
        }
      } catch {
        //
      }
      this.hidepage = false;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      /** set prev route */
      vm.$set(vm, 'prevRoute', from);
    });
  },
  watch: {
    'form.password': function fn(val) {
      this.pwdErr = val.length === 0;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) return;
      this.pwdErr = false;
      const data = this.form;
      if (this.$route.query.order_id) data.order_id = this.$route.query.order_id;
      try {
        const res = await this.callApi({ url: this.loginApi, method: 'post', data });

        if (this.$route.query.redirect || res.data.redirect) {
          this.$router.push({
            name: this.$route.query.redirect || res.data.redirect,
            params: { ...this.$route.params, ...res.data.params },
          });
        } else {
          this.$router.push({
            name: configEnv.login_success_redirect_page,
            params: { ...this.$route.params },
          });
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.pwdErr = true;
        }
      }
    },
    getUserStatus() {
      /** call user api for get status */
      return this.callApi(this.userStatusApi);
    },
    back() {
      this.$router.back();
    },
    async checkMagicLink() {
      const { magic_link, order_id } = this.$route.query;
      let to = false;
      if (magic_link) {
        LogService.log(JSON.stringify(this.$route.query, null, 2));
        const url = order_id ? `${this.magicOrderApi}/${order_id}` : this.magicLoginApi;
        const headers = {
          'Magic-Code': magic_link,
        };
        try {
          const res = await this.callApi({ url, headers });
          const hasUrlRedirect = this.$route.query.redirect
            && this.availableUrlRedirect.includes(this.$route.query.redirect);
          const name = hasUrlRedirect ? this.$route.query.redirect : res.data.redirect;
          const id = res.data.params?.id;
          if (name && id && !hasUrlRedirect) {
            to = { name, params: { ...this.$route.params, id } };
          } else if (name) {
            to = { name, params: { ...this.$route.params, id } };
            if (res.data.query) {
              to.query = res.data.query;
            }
          }
          LogService.log('to', to);
        } catch (error) {
          if (['404', '400'].includes(error.response?.status.toString())) {
            /** show popup */
            const message = this.strings?.login?.error_magic_nofound;
            const modal = {
              id: 'wet-login-magic-modal',
              content: `<div class="text-center">${message}</div>`,
              actions: [
                {
                  name: 'wet-login-magic-ok',
                  title: this.strings.commonApp?.ok,
                  event: 'close',
                },
              ],
            };
            this.$store.dispatch('addModal', modal);
          } else LogService.log(error);
        }
      }
      return to;
    },
  },
};
</script>
<style lang="scss">
  .wet-separated-title {
    border-bottom: 1px solid var(--gray-light);
    color: var(--gray);
    width: 100%;
    position: relative;
    margin: 2.25rem 0;

    &>* {
      position: absolute;
      color: inherit;
      top: 50%;
      left: 50%;
      line-height: 1;
      background-color: white;
      padding: .25rem 2rem;
      transform: translate(-50%, -50%);
    }
  }
</style>
